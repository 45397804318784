'use client';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';

import { signIn } from 'next-auth/react';

import { FcGoogle } from 'react-icons/fc';

import { z } from 'zod';

import { AuthType, Vendors } from '@/enums/core';
import { FormEmailAndPassword, FormPhoneCodeAndPassword } from '@/features/auth/components';
import ForgotPassword from '@/features/auth/components/ForgotPassword';
import WebView from '@/features/auth/components/WebView';
import { FirebaseStatus } from '@/features/auth/enums';
import { messages } from '@/features/auth/localization';

import { core, errors } from '@/localization';

import { userUIStore } from '@/stores/userUIStore';

import { exception } from '@/utils/core';
import { handleSignInWithEmailAndPassword, handleSignInWithGoogle } from '@/utils/firebaseOptions';

import { FAKE_EMAIL } from '@/constants/core';

import {
  Dialog, DialogContent, DialogTitle, DialogTrigger,
} from '@/components/ui/dialog';

import { useToast } from '@/components/ui/use-toast';

import { Button } from '../ui/button';

interface Props {
  isWebView:boolean
}

const LoginModal = ({ isWebView }:Props) => {
  const [isMobileForm, setIsMobileForm] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { isLoginModalOpen, toggleLoginModal, toggleRegisterModal } = userUIStore();
  const url = usePathname();
  const params = useSearchParams();

  const urlRedirect = `${url}?gamecode=${params.get('gamecode')}&provider=${params.get('provider')}`;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider mobile if width <= 768px
    };

    handleResize(); // Initialize on first render
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  const handleBackFromForgotPassword = () => {
    setShowForgotPassword(false);
  };
  const { toast } = useToast();

  // SIGNIN
  const handleGoogleSignIn = async () => {
    const credential = await handleSignInWithGoogle();

    try {
      setLoading(true);
      // const credential = await handleSignInWithGoogle();
      // eslint-disable-next-line no-console
      console.log('credential', credential);
      if (credential && credential.user) {
        const options = {
          callbackUrl: url.includes('/casino/play') ? urlRedirect : '/',
          email: credential.user?.email,
          password: credential.user?.uid,

          image: credential.user?.photoURL ?? '',
          type: AuthType.SIGNIN,
          vendor: Vendors.GOOGLE,
        };
        await signIn('credentials', options);
      } else {
        setLoading(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      await exception(error, { route: '/components/modals/LoginModal.tsx: handleGoogleSignIn', method: 'POST', req: credential });
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleEmailAndPassSignIn = async (values: z.infer<any>):Promise<void> => {
    try {
      setLoading(true);
      if (values.email && values.password) {
        const credential = await handleSignInWithEmailAndPassword(values.email, values.password);
        if (credential && typeof credential !== 'string') {
          const options = {
            email: credential.user?.email,
            password: credential.user?.uid,
            callbackUrl: '/',
            type: AuthType.SIGNIN,
            vendor: Vendors.EMAIL,
          };
          await signIn('credentials', options);
        } else if (credential && typeof credential === 'string' && credential === FirebaseStatus.AUTH_INVALID_CREDENTIALS) {
          // eslint-disable-next-line no-console
          console.log('error credential', credential);
          setLoading(false);
          toast({ title: messages.errorInvalidCredentials, variant: 'destructive' });
        } else toast({ title: errors.tryAgain, variant: 'destructive' });
      } else toast({ title: errors.tryAgain, variant: 'destructive' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      await exception(error, { route: '/components/modals/LoginModal.tsx: handleEmailAndPassSignIn', method: 'POST', req: { values } });
      toast({ title: errors.tryAgain, variant: 'destructive' });
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneAndPassSignIn = async (values: z.infer<any>):Promise<void> => {
    try {
      setLoading(true);
      if (values.opt && values.password) {
        const email = `${values.opt.replace(/\D/g, '')}${FAKE_EMAIL}`;
        const credential = await handleSignInWithEmailAndPassword(email, values.password);
        if (credential && typeof credential !== 'string') {
          const options = {
            email: credential.user?.email,
            password: credential.user?.uid,
            callbackUrl: '/',
            type: AuthType.SIGNIN,
            vendor: Vendors.PHONE,
          };
          await signIn('credentials', options);
        } else if (credential && typeof credential === 'string' && credential === FirebaseStatus.AUTH_INVALID_CREDENTIALS) {
          setLoading(false);
          toast({ title: messages.errorInvalidPhoneNumber, variant: 'destructive' });
        } else toast({ title: errors.tryAgain, variant: 'destructive' });
      } else toast({ title: errors.tryAgain, variant: 'destructive' });
    } catch (error) {
      await exception(error, { route: '/components/modals/LoginModal.tsx: handlePhoneAndPassSignIn', method: 'POST', req: { values } });
      toast({ title: errors.tryAgain, variant: 'destructive' });
    } finally {
      setLoading(false);
    }
  };

  // SIGNIN\
  return (
    <Dialog
      modal={false}
      open={isLoginModalOpen}
      onOpenChange={toggleLoginModal}
    >
      <DialogTrigger asChild>
        <Button className="bg-sivarbet-primary text-sivarbet-background font-semibold" size="sm" variant="secondary">
          {messages.login}
        </Button>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => { return e.preventDefault(); }}
        onInteractOutside={(e) => { e.preventDefault(); }}
        className="bg-sivarbet-secondary-bg border-none p-0 flex flex-col lg:flex-row lg:flex-nowrap lg:max-w-[816px] lg:max-h-[630px] h-full rounded-lg"
      >
        <div className={`relative ${isMobile ? 'w-full h-[200px]' : 'w-1/2 h-auto'}`}>
          <Image
            src={isMobile ? '/img/modal/loginmobile.webp' : '/img/modal/login.webp'}
            alt="Login Promo"
            fill
            objectFit="cover"
            objectPosition="center"
            sizes="(max-width: 768px) 100vw, 50vw"
            priority
          />
        </div>
        <section className="lg:w-[50%] flex flex-col justify-center h-full pb-5">
          {isWebView ? (<WebView />) : (
            <>
              <DialogTitle className="flex items-center justify-start relative text-inherit leading-[20px] font-medium font-inherit mx-[17px] mb-8">
                {`${messages.enterTo} ${core.appName}`}
              </DialogTitle>
              <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] max-lg:hidden mb-6">
                <span className="mr-2">{messages.dontHaveAnAccount}</span>
                <span
                  onClick={() => {
                    toggleLoginModal();
                    toggleRegisterModal();
                  }}
                  className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
                >
                  {messages.enterHere}
                </span>
              </div>
              {
                showForgotPassword ? (
                  <ForgotPassword onBack={handleBackFromForgotPassword} />
                ) : (
                  <>
                    {isMobileForm && <FormPhoneCodeAndPassword action={handlePhoneAndPassSignIn} disabled={loading} isRegister={false} /> }
                    {!isMobileForm && <FormEmailAndPassword action={handleEmailAndPassSignIn} disabled={loading} isRegister={false} />}
                    <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] lg:hidden mb-5">
                      <span>{messages.dontHaveAnAccount}</span>
                      <span
                        onClick={() => {
                          toggleLoginModal();
                          toggleRegisterModal();
                        }}
                        className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
                      >
                        {messages.enterHere}
                      </span>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] text-mini text-third_text mx-[17px]">
                      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px]">
                        <div className="center-box">
                          <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-sivarbet-border" />
                        </div>
                        <div className="relative leading-[11px] inline-block min-w-[104px]">
                          {messages.orContinueWith}
                        </div>
                        <div className="center-box">
                          <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-sivarbet-border" />
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                        <Button disabled={loading} onClick={() => { setIsMobileForm(!isMobileForm); }} type="button" className="cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px]">
                          <span className={`icon icon-${!isMobileForm ? 'phone' : 'mail'} text-sivarbet-primary mt-1`} />
                          <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
                            <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                              {`${messages.continueWith} ${isMobileForm ? messages.yourEmail.toLocaleLowerCase() : messages.yourPhone.toLocaleLowerCase()}`}
                            </div>
                          </div>
                        </Button>
                        <Button disabled={loading} onClick={handleGoogleSignIn} type="button" className="cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-start justify-center gap-[8px]">
                          <FcGoogle className="text-lg" />
                          <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
                            <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                              {`${messages.continueWith} ${messages.google}`}
                            </div>
                          </div>
                        </Button>

                      </div>
                      <div className="text-center mt-4 items-center">
                        <span
                          onClick={toggleForgotPassword}
                          className="text-sivarbet-primary cursor-pointer hover:underline"
                        >
                          {messages.forgotPassword}
                        </span>
                      </div>
                    </div>
                  </>
                )
              }
            </>
          )}
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
