'use client';

import React from 'react';

import { Session } from 'next-auth';

import RegistrationPopup from '@/components/modals/RegistrationPopup';

import NavBarAuthenticated from '@/components/navbar/NavBarAuthenticated';
import PublicNavBar from '@/components/navbar/PublicNavbar';

const NavBar = ({ session }: { session: Session | null }) => {
  // useEffect(() => {
  //   const handleRedirect = async () => {
  //     if (typeof window !== 'undefined') {
  //       try {
  //         const result = await getRedirectResult(auth);
  //         if (result) {
  //           const token = await result.user.getIdToken();
  //           if (result.user.email) {
  //             login({
  //               uid: result.user.uid,
  //               email: result.user.email,
  //               emailVerified: result.user.emailVerified,
  //               token,
  //             });
  //           }
  //         }
  //       } catch (error: any) {
  //         // eslint-disable-next-line no-console
  //         console.error('SignIn Error:', error);
  //         toast({
  //           title: 'Error Iniciando Sesion',
  //           description: error.message,
  //         });
  //       }
  //     }
  //   };

  //   handleRedirect();

  //   const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
  //     if (firebaseUser) {
  //       const emailVerifiedChanged = firebaseUser.emailVerified !== prevEmailVerified.current;
  //       prevEmailVerified.current = firebaseUser.emailVerified;

  //       if (firebaseUser.email) {
  //         login({
  //           uid: firebaseUser.uid,
  //           email: firebaseUser.email,
  //           emailVerified: firebaseUser.emailVerified,
  //         });
  //       }

  //       if (emailVerifiedChanged && firebaseUser.emailVerified && user) {
  //         setEmailVerified(firebaseUser.emailVerified);
  //         fetch('/api/dashboardData/identity', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({
  //             id_usuario: user?.id_usuario,
  //             emailVerificado: true,
  //           }),
  //         });
  //       }
  //     } else {
  //       logout();
  //     }
  //   });

  //   return () => {
  //     return unsubscribe();
  //   };
  // }, [login, logout, setEmailVerified, toast, user]);

  return !session ? (
    <PublicNavBar />
  ) : (
    <>
      <NavBarAuthenticated session={session} />
      <RegistrationPopup isOpen={session?.user?.fullProfile ?? false} session={session} />
    </>
  );
};

export default NavBar;
