import React from 'react';

import Link from 'next/link';

import { BsYoutube } from 'react-icons/bs';
import { FaLinkedin, FaTelegram, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const SocialLinks = () => {
  return (
    <div>
      <h4 className="info-header">Síguenos</h4>
      <ul className="text-sivarbet-secondary-text flex flex-row flex-wrap gap-4 lg:w-44">
        <li>
          <Link href="https://www.instagram.com/hondubet_/" target="_blank"><span className="icon icon-instagram text-3xl" /></Link>
        </li>
        <li>
          <Link href="https://www.facebook.com/Hondubet/" target="_blank"><span className="icon icon-facebook text-3xl" /></Link>
        </li>
        <li>
          <Link href="https://api.whatsapp.com/send?phone=50492062735&text=%C2%A1Hola" target="_blank"><span className="icon icon-whatsapp text-3xl" /></Link>
        </li>
        <li>
          <Link href="https://www.tiktok.com/@hondubet" target="_blank"><FaTiktok className="text-3xl" /></Link>
        </li>
        <li>
          <Link href="https://www.youtube.com/channel/UCCwBBMSC-SPQOOiRSmlQ2gQ" target="_blank"><BsYoutube className="text-3xl" /></Link>
        </li>
        <li>
          <Link href="https://x.com/hondubet" target="_blank"><FaXTwitter className="text-3xl" /></Link>
        </li>
        <li>
          <Link href="https://t.me/Hondubet2024" target="_blank"><FaTelegram className="text-3xl" /></Link>
        </li>
        <li>
          <Link href="https://www.linkedin.com/in/hondubet-apuestas-deportivas-9b75b1220/?originalSubdomain=hn" target="_blank"><FaLinkedin className="text-3xl" /></Link>
        </li>
      </ul>
    </div>
  );
};

export default SocialLinks;
