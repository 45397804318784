import Image from 'next/image';

import { userUIStore } from '@/stores/userUIStore';

import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/components/ui/tabs';

import BankContent from './BankContent';

const bankDetails = {
  banpais: {
    details:
      'Opción 1:Cta de ahorro Lempiras: 215990026290\nOpción 2: Cta de Cheques Lempiras: 015990023506',
    instructions:
      'Querido usuario; una vez realices el depósito, debes enviar foto o capture del comprobante, acompañado de tu ID de usuario a nuestra línea de WhatsApp +504 9206-2735.',
    rows: 4,
  },
  bac: {
    details: 'Opción 1: AHORROS: 748498871\nOpción 2: CHEQUES: 730470431',
    instructions:
      'Querido usuario; una vez realices el depósito, debes enviar foto o capture del comprobante, acompañado de tu ID de usuario a nuestra línea de WhatsApp +504 9206-2735.',
    rows: 4,
  },
};

const BankDeposit = () => {
  const { setOpenDeposit } = userUIStore();
  return (
    <div className="p-3">
      <Tabs>
        <TabsList className="grid grid-cols-2 gap-3 w-full h-full bg-sivarbet-secondary-bg py-2 mt-2">
          <TabsTrigger
            value="banpais"
            className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
          >
            <Image
              src="/img/footer/banpais.png"
              alt="Banpais"
              width={100}
              height={100}
            />
          </TabsTrigger>
          <TabsTrigger
            value="bac"
            className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
          >
            <Image src="/img/footer/bac.png" alt="Bac" width={100} height={100} />
          </TabsTrigger>
        </TabsList>
        <TabsContent value="banpais">
          <BankContent
            bankDetails={bankDetails}
            selectedPaymentMethod="banpais"
          />
        </TabsContent>
        <TabsContent value="bac">
          <BankContent bankDetails={bankDetails} selectedPaymentMethod="bac" />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default BankDeposit;
