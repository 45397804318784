import Image from 'next/image';

import Link from 'next/link';

import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/components/ui/tabs';

import CashContent from './CashContent';

const cashDetails = {
  tigo: {
    instructions:
  <div className="text-sivarbet-text-primary">
    <h3 className="mb-5">🔵 TIGOMONEY: Pasos para recargar por TIGOMONEY </h3>
    <p>
      Acércate al punto más cercano e indica que vas a realizar un depósito a tu cuenta Hondubet.
      Recuerda tener presente tu número de ID de tu cuenta.
    </p>
    <span>Monto mínimo de recarga: 250 HNL.</span>
    <span className="mb-5">Tu recarga será acreditada de manera automática.</span>
    <span className="mb-5">
      🔵 Puntos Tigo Money
    </span>
    <span>
      En el siguiente link podrás encontrar los
      <Link href="https://lc.cx/fLPUhN">puntos disponibles de Tigo Money</Link>
    </span>
  </div>,
  },
};

const CashDeposit = () => {
  return (
    <Tabs>
      <TabsList className="w-full h-full bg-sivarbet-secondary-bg py-2 mt-2">
        <TabsTrigger value="tigo" className="flex w-full justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full">
          <Image src="/img/footer/tigo.png" alt="tigo" width={200} height={200} />
        </TabsTrigger>
      </TabsList>
      <TabsContent value="tigo">
        <CashContent bankDetails={cashDetails} selectedPaymentMethod="tigo" />
      </TabsContent>
    </Tabs>
  );
};

export default CashDeposit;
