/* eslint-disable react/require-default-props */

'use client';

import React, { useState, useEffect, memo } from 'react';

import SlotCounter from 'react-slot-counter';

import { core } from '@/localization';

import { useBalanceStore } from '@/stores/balanceStore';

import { moneyFormat } from '@/utils/core';

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
} from '@/components/ui/dropdown-menu';

type Props = {
  data: any,
  // available_balance: number,
  // deposit_balance: number,
  // isOpen?: boolean,
};
const SelectBalance = ({
  data,
}: Props) => {
  const [selected, setSelected] = useState(data[0] ?? 0);
  const { balance: amount } = useBalanceStore();
  const [prevAmount, setPrevAmount] = useState<number>(amount);

  useEffect(() => {
    setSelected(data[0]);
    setPrevAmount(data[0]?.amount ?? 0);
  }, [data]);

  useEffect(() => {
    if (selected && selected?.amount !== prevAmount) {
      setPrevAmount(selected?.amount ?? 0);
    }
  }, [prevAmount, selected]);

  const renderPopover = (balance: {
    type: string;
    amount: number;
    description: string;
    color?: string;
  }) => {
    let popoverBodyContent;

    if (balance.description === 'Saldo disponible') {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>
            Saldo que has liberado.
            Este saldo es el que puedes retirar. Para solicitar un retiro navega a tu perfil y dale click a la opcion de retirar
            {' '}
          </div>
        </div>
      );
    } else if (balance.description === 'Saldo deposito') {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          Saldo que has depositado.
          nota que el saldo depositado no se puede retirar hasta no liberalo.
          para liberar saldo lo debes de jugar al menos una vez. Para mas informacion acede la academia de sivarbet.
          <br />
          <b>academia.hondubet.com</b>
        </div>
      );
    } else if (balance.description === 'Bonos casino') {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>
            freespins en casino. Los freespins estan limitados a ciertos juegos y tienen un tiempo de expiracion. Para mas informacion acede la academia de sivarbet.
            <br />
            <b>academia.hondubet.com</b>
          </div>
        </div>
      );
    } else {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>Bonos Deportivos sin liberar. este saldo se puede usar en apuestas deportivas </div>
        </div>
      );
    }

    return (
      <div className="bg-sivarbet-secondary-bg p-4 rounded-md shadow-lg">
        <h3 className="font-bold">{balance.description}</h3>
        {popoverBodyContent}
      </div>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger data-testid="select-button" className="p-2 py-3 px-3 flex items-center rounded-lg bg-sivarbet-secondary-bg max-md:p-1 max-md:py-2 max-md:px-2">
        <span className="text-sivarbet-secondary icon icon-coins_line max-md:text-xs max-sm:text-2xs" />
        <span className="text-sivarbet-secondary pl-2 flex items-center max-md:pl-1 max-md:text-sm">
          <SlotCounter
            value={moneyFormat(
              amount,
            )}
            startValueOnce
            duration={2}
            animateUnchanged
            autoAnimationStart
            animateOnVisible={{ triggerOnce: false, rootMargin: '0px 0px -100px 0px' }}
          />
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="select-balance-menu bg-sivarbet-background border-sivarbet-border text-sivarbet-text ml-8 w-full">
        <DropdownMenuLabel className="text-lg flex flex-row items-center gap-1 pb-4">
          <span className="fill-sivarbet-secondary icon icon-coins_line" />
          Saldos
        </DropdownMenuLabel>
        <DropdownMenuLabel className="text-sivarbet-secondary font-bold text-xl pb-5">
          {`${core.totalBalance}`}
          <span className="font-normal">
            {` ${moneyFormat(
              amount.toFixed(2),
            )}`}
          </span>
        </DropdownMenuLabel>
        <div className="text-nowrap">
          {data.filter((_:any, index:number) => { return index > 0; }).map((item:any) => {
            return (
              <React.Fragment key={item.description}>
                <DropdownMenuItem className="hover:bg-sivarbet-primary-hover focus:bg-sivarbet-primary-hover" onClick={() => { return setSelected(item); }}>
                  <div className="flex justify-between items-center gap-x-28 w-full text-lg">
                    <span>{`L ${moneyFormat(item.amount)}`}</span>
                    <div className="flex justify-end items-center">
                      <span>{item.description}</span>
                      <DropdownMenuSub>
                        <DropdownMenuSubTrigger data-testid="popover" className="end-full hover:bg-transparent focus:bg-transparent hover:text-sivarbet-primary data-[state=open]:bg-transparent">
                          <span className="fill-white icon icon-info" />
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent className="w-1/2 bg-sivarbet-secondary-bg text-sivarbet-primary border-sivarbet-border">
                            {renderPopover(item)}
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>
                    </div>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuSeparator className="bg-sivarbet-border" />
              </React.Fragment>
            );
          })}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(SelectBalance);
