/* eslint-disable no-console */

'use client';

import React, {
  useState, useEffect,
} from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { Session } from 'next-auth';
import { signOut, useSession } from 'next-auth/react';
import { IoCloseOutline, IoMenuSharp } from 'react-icons/io5';
import useSWR from 'swr';

// import BonusModal from '@/features/Bonus/BonusModal';
import GiftBoxAnimation from '@/features/freespins/AnimateGift';
import { useBalanceStore } from '@/stores/balanceStore';
import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import { decrypt } from '@/utils/core';

import { Amounts } from '@/interfaces/core';

import DepositModal from '@/components/modals/DepositModal';
import Notifications from '@/components/select/Notifications';
import SelectBalance from '@/components/select/SelectBalances';

import UserMenu from '@/components/select/UserMenu';
import { Button } from '@/components/ui/button';

import LogoL from '@/public/img/logo/hondubetlogo.png';
import LogoS from '@/public/img/logo/hondubetsquare.png';

import DepositSheet from '../sheet/DepositSheet';
import NotificationsSheet from '../sheet/NotificationsSheet';
import SelectBalanceSheet from '../sheet/SelectBalancesSheet';

const fetcher = async (...args: Parameters<typeof fetch>) => {
  const res = await fetch(...args);
  return res.json();
};

const DashboardNavBar = ({ session }: { session: Session }) => {
  const pathname = usePathname();
  const [currentPathname, setCurrentPathname] = useState<string | null>(null);
  const searchParams = useSearchParams();
  const {
    isSidebarOpen, toggleSidebar, resetSidebar, setIsAmountsLoaded,
  } = userUIStore();
  const { setBalance } = useBalanceStore();

  const [refreshInterval, setRefreshInterval] = useState(30000);
  const [isUserIdle, setIsUserIdle] = useState(false);
  const [amounts, setAmounts] = useState<Amounts[] | null>(null);
  const { isxtremepushSet, setXtremepush } = useAuthStore();
  // const [open, setOpen] = useState(false);
  const router = useRouter();
  const { update } = useSession();

  useEffect(() => {
    setCurrentPathname(pathname);
  }, [pathname]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notificationCount, setNotificationCount] = useState(0); // State for notification count

  useEffect(() => {
    resetSidebar(); // Reset the sidebar state whenever the route changes
  }, [pathname, resetSidebar, searchParams]); // Reacting to changes in path or search params

  useEffect(() => {
    const fetchBadgeCount = async () => {
      if (session.user?.data && session.user?.tag) {
        const userId = await decrypt(session.user?.data, session.user?.tag);
        if ((window as any).xtremepush) {
          if (!isxtremepushSet) {
            (window as any).xtremepush('set', 'user_id', userId);
            setXtremepush();
          }
          await (window as any).xtremepush(
            'inbox',
            'badge',
            {},
            (result: any) => {
              if (result.badge !== undefined) {
                setNotificationCount(result.badge); // Update the badge count
              }
            },
          );
        }
      }
    };
    fetchBadgeCount(); // Call on component mount
  }, [isxtremepushSet, session.user?.data, session.user?.tag, setXtremepush]);

  const handleHeaderBarClick = async () => {
    if (window.innerWidth > 768) {
      toggleSidebar();
    }
  };

  /*   const openNotification = () => {
    toggleNotification();
  }; */

  useEffect(() => {
    let idleTimer: string | number | NodeJS.Timeout | undefined;
    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      setIsUserIdle(false);
      idleTimer = setTimeout(() => { return setIsUserIdle(true); }, 3 * 60 * 1000); // 3 minutes
    };

    // Reset the timer when user interacts
    window.addEventListener('mousemove', resetIdleTimer);
    window.addEventListener('keypress', resetIdleTimer);

    // Set the initial timer
    resetIdleTimer();

    return () => {
      clearTimeout(idleTimer);
      window.removeEventListener('mousemove', resetIdleTimer);
      window.removeEventListener('keypress', resetIdleTimer);
    };
  }, []);

  // Adjust refreshInterval based on route and idle status
  useEffect(() => {
    if (typeof currentPathname === 'string' && currentPathname !== null) {
      if (currentPathname.startsWith('/casino/play')) {
        setRefreshInterval(5000); // Increase frequency for /play/* pages
      } else {
        console.warn('currentPathname', currentPathname);
        setRefreshInterval(30000); // Default frequency for other pages
      }
    } else if (typeof window !== 'undefined') {
      // Handle cases where pathname is null or undefined after hydration
      setRefreshInterval(30000); // Set a default refresh interval
      console.warn('Current pathname is null or undefined. Check the URL or rehydration.');
    }
  }, [currentPathname, isUserIdle]);

  // check if the bonus is won and set the open state to true
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const getCookie = (name: string): string | undefined => {
  //       const value = `; ${document.cookie}`;
  //       const parts = value.split(`; ${name}=`);
  //       if (parts.length === 2) return parts.pop()?.split(';').shift();
  //       return undefined;
  //     };

  //     const bonusWinner = getCookie('bonus-winner');
  //     if (bonusWinner) {
  //       setOpen(true);
  //     }
  //   }
  // }, [pathname]);

  const { isLoading } = useSWR(
    `/api/balance?ref=${session.user?.uid}`,
    fetcher,
    {
      refreshInterval: isUserIdle ? 0 : refreshInterval,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: async (data) => {
        let shouldRefresh = false;

        // Check if the status requires sign out
        if (data && data?.status === false) {
          signOut();
        }

        // Update KYC status if it has changed
        if (data && data?.data && data?.kycStatus !== null && data?.kycLevel !== null) {
          if (data?.kycStatus !== session.user?.kyc_status || data?.kycLevel !== session.user?.kyc_level) {
            console.log('kycStatus', data?.kycStatus);
            await update({
              kycStatus: data?.kycStatus,
              kycLevel: data?.kycLevel,
              triggerType: 'kycStatus',
            });
            shouldRefresh = true;
          }
        }

        // Update email verification status if it has changed
        if (data && data?.data && data?.emailVerified !== null) {
          if (data?.emailVerified !== session.user?.email_verified) {
            console.log('emailVerified', data?.emailVerified);
            await update({
              email_verified: data?.emailVerified,
              triggerType: 'emailVerified',
            });
            shouldRefresh = true;
          }
        }

        // Update first deposit status if it has changed
        if (data && data.data && data?.firstDeposit !== null) {
          if (data?.firstDeposit !== session.user?.firstDeposit) {
            console.log('firstDeposit', data?.firstDeposit);
            await update({
              firstDeposit: data?.firstDeposit,
              triggerType: 'firstDeposit',
            });
            shouldRefresh = true;
          }
        }
        if (data && data?.data && data?.data[0].amount) {
          if (data?.data[0].amount !== amounts?.[0]?.amount) {
            setAmounts(data?.data);
          }
        }
        // Only refresh if any value has changed
        if (shouldRefresh) {
          // eslint-disable-next-line no-console
          console.log('shouldRefresh', shouldRefresh);
          shouldRefresh = false;
          router.refresh();
        }
      },
    },
  );

  useEffect(() => {
    if (amounts && amounts[0].amount > 0) {
      console.log('amounts2', amounts);
      setIsAmountsLoaded(true);
      setBalance(amounts[0].amount);
    }
  }, [amounts, setIsAmountsLoaded, setBalance]);

  // redirect to the sport page if the bonus is won and delete the cookie
  // const CloseBonusModal = (sport: boolean) => {
  //   setOpen(false);
  //   document.cookie = 'bonus-winner=; path=/; max-age=0';
  //   if (sport) {
  //     router.push('/deportes');
  //   }
  // };

  return (
    <header className="bg-sivarbet-background flex w-full">
      <div className="p-0 flex-1 w-full">
        <div className="w-full flex justify-between items-center py-1 px-4 max-lg:px-2 bg-gray-900 text-sivarbet-text border-b border-b-sivarbet-border shadow drop-shadow-xl shadow-black max-lg:justify-normal max-lg:gap-1">
          <div
            className="pl-2 pr-9 flex justify-center items-center transition-all duration-1000 max-lg:hidden"
          >
            <Button
              onClick={handleHeaderBarClick}
              variant="default"
              className="bg-transparent border-none p-0 hover:bg-none"
            >
              {isSidebarOpen ? (
                <IoMenuSharp className="text-4xl text-slategray transition-all duration-1000" />
              ) : (
                <IoCloseOutline className="text-4xl text-slategray transition-all duration-1000" />
              )}
            </Button>
            <Link href="/">
              <Image src={LogoL} alt="logo" width={250} height={90} className="ml-4" />
            </Link>
          </div>
          <div className="max-lg:block max-lg:mx-2 hidden">
            <Link href="/">
              <Image src={LogoS} alt="logo" width={55} height={55} className="" />
            </Link>
          </div>
          <div className="flex items-center pl-1 w-full gap-4 max-lg:gap-4">

            {isLoading && <div className="spinner" />}
            <div className="max-lg:hidden overflow-auto">

              <SelectBalance data={amounts ?? []} />
            </div>
            <div className="lg:hidden overflow-auto">
              <SelectBalanceSheet data={amounts ?? []} />
            </div>
            <div className="max-lg:hidden">
              <DepositModal session={session} />
            </div>
            <div className="lg:hidden flex ">
              <DepositSheet session={session} />
            </div>
            <div className="max-lg:hidden">
              { amounts && amounts[3].amount > 0 && <GiftBoxAnimation amount={Number(amounts[3].amount)} /> }
            </div>
          </div>

          <div className="flex items-center gap-4 max-lg:gap-2">
            <div className="lg:hidden">
              { amounts && amounts[3].amount > 0 && <GiftBoxAnimation amount={Number(amounts[3].amount)} /> }
            </div>
            <Notifications />
            <NotificationsSheet />
            <UserMenu />
          </div>
        </div>
      </div>
      {/* <BonusModal open={open} setOpen={CloseBonusModal} /> */}
    </header>
  );
};

export default DashboardNavBar;
