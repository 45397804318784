import { Currency, Gateway, TransactionTypes } from '@/enums/core';

import { exception } from '@/utils/core';
import { prisma } from '@/utils/prisma';

interface QueryResponse {
  status: boolean;
  data: any;
}

export const findWallet = async (user_id: number, currency: string) => {
  try {
    const wallet = await prisma.wallets.findFirst({
      where: {
        user_id,
        currency,
        status: true,
      },
      select: {
        currency: true,
        status: true,
        address: true,
        id: true,
        network: true,
      },
    });

    return wallet;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findWallet',
      method: 'GET',
      req: { user_id, currency },
    });
    return null;
  }
};

export const findWalletByAddress = async (address: string) => {
  const wallet = await prisma.wallets.findFirst({
    where: {
      address,
    },
  });
  return wallet;
};

export const createWallet = async (
  user_id: number,
  currency: string,
  address: string,
  method: number,
  type?: number,
  network?: number,
  bank?: string,
  status = true,
  // eslint-disable-next-line max-params
) => {
  const newWallet = await prisma.wallets.create({
    data: {
      wallet_id: 0,
      user_id,
      currency,
      address,
      method,
      type: type || 1,
      status,
      network: network || 0,
      bank: bank || '',
    },
  });

  return newWallet;
};

export const updateBalance = async (
  user_id: number,
  status: number,
  type: number,
  amount: number,
  currency: number,
  gateway: number,
  id_txn: string,
  deposit_id: string,
  description: string,
  // eslint-disable-next-line max-params
) => {
  try {
    // find if the transaction already exists if so then update the status
    const existingTransaction = await prisma.transactions.findFirst({
      where: {
        deposit_id,
      },
    });

    // If the transaction exists, update it
    if (existingTransaction) {
      const updatedTransaction = await prisma.transactions.update({
        where: {
          id: existingTransaction.id,
        },
        data: {
          status,
          amount,
          description,
        },
      });

      return {
        transaction: updatedTransaction,
        processed: true,
      };
    }

    const newTransaction = await prisma.transactions.create({
      data: {
        user_id,
        status,
        type,
        amount,
        currency,
        gateway,
        id_txn,
        deposit_id,
        description,
      },
    });

    return {
      transaction: newTransaction,
      processed: false,
    };
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: updateBalance',
      method: 'POST',
      req: {
        user_id,
        status,
        type,
        amount,
        currency,
        gateway,
        id_txn,
        deposit_id,
        description,
      },
    });
    return null;
  }
};

export async function findDepositWallet(
  address: string,
): Promise<QueryResponse> {
  try {
    const wallet = await prisma.wallets.findFirst({
      where: {
        address,
      },
      select: {
        currency: true,
        status: true,
        address: true,
        user_id: true,
        id: true,
        network: true,
      },
    });

    return { status: true, data: wallet };
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findDepositWallet',
      method: 'GET',
      req: address,
    });
    return { status: false, data: null };
  }
}

export async function findFirstDeposit(user_id: number) {
  try {
    const user = await prisma.users.findFirst({
      where: {
        id: user_id,
      },
      select: {
        email: true,
        profiles: {
          select: {
            first_deposit: true,
          },
        },
      },
    });
    const data = {
      email: user?.email,
      first_deposit: user?.profiles?.first_deposit,
    };
    return data;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findFirstDeposit',
      method: 'GET',
      req: { user_id },
    });
    return {
      email: undefined,
      first_deposit: undefined,
    };
  }
}

export async function findUserInAltenar(user_id: number) {
  try {
    const bonus = await prisma.users.findFirst({
      where: {
        id: user_id,
        status: true,
        altenar: {
          not: null,
        },
      },
      select: {
        altenar: true,
      },
    });
    return !!bonus?.altenar;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findBonusByDeposit',
      method: 'GET',
      req: { user_id },
    });
    return false;
  }
}

export async function updateBalanceByAddress({
  address,
  status,
  amount,
  idTxn,
  depositId,
}: {
  address: string;
  amount: number;
  status: number;
  idTxn: string;
  depositId: string;
}) {
  try {
    if (!address || address === '') {
      return { status: false, data: null };
    }
    const wallet = await findWalletByAddress(address);
    if (wallet) {
      await updateBalance(
        wallet.user_id,
        status,
        TransactionTypes.DEPOSIT,
        amount,
        Currency.HNL,
        Gateway.COINPAYMENTS,
        idTxn,
        depositId,
        '',
      );
      return { status: true, data: wallet };
    }
    return { status: false, data: null };
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: updateBalanceByAddress',
      method: 'POST',
      req: { address, amount },
    });
    return { status: false, data: null };
  }
}
