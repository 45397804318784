import { IoCopyOutline } from 'react-icons/io5';

import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';

interface Props {
  bankDetails: any;
  selectedPaymentMethod: string;
}

interface Props {
  bankDetails: any;
  selectedPaymentMethod: string;
}

const BankContent = ({ bankDetails, selectedPaymentMethod }: Props) => {
  const { toast } = useToast();

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: 'Texto copiado al portapapeles',
    });
  };

  return (
    <div className="mt-4 flex flex-col items-center justify-stretch">
      <h4 className="text-white">Importante!</h4>
      <p className="text-xs leading-5 text-white">
        Recuerda que el monto mínimo de recarga son 100 Lempiras
      </p>
      <h5 className="my-1 text-white">Detalles Bancarios</h5>
      <div className="relative w-full">
        <Textarea
          readOnly
          value={bankDetails[selectedPaymentMethod].details}
          rows={bankDetails[selectedPaymentMethod].rows || 1}
          className="w-full bg-sivarbet-secondary-bg border-none"
        />
        <IoCopyOutline
          onClick={() => { return handleCopy(bankDetails[selectedPaymentMethod].details); }}
          className="absolute right-2 bottom-2 cursor-pointer text-white"
        />
      </div>
      <h5 className="my-1 text-left text-white">Instrucciones:</h5>
      <p className="text-xs leading-5 text-white text-center">
        {bankDetails[selectedPaymentMethod].instructions}
      </p>
    </div>
  );
};

export default BankContent;
