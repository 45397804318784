export const messages = {
  errorInvalidNameMin: 'El nombre debe tener al menos 2 caracteres',
  errorInvalidNameMax: 'El nombre debe tener como máximo 50 caracteres',
  errorInvalidSecondNameMin: 'El segundo nombre debe tener al menos 2 caracteres',
  errorInvalidSecondNameMax: 'El segundo nombre debe tener como máximo 50 caracteres',
  errorInvalidLastNameMin: 'El apellido debe tener al menos 2 caracteres',
  errorInvalidLastNameMax: 'El apellido debe tener como máximo 50 caracteres',
  errorInvalidBirthdate: 'La fecha de nacimiento es requerida',
  errorInvalidBirthdateMin: 'Debe ser mayor de edad',
  errorInvalidCountry: 'El país es requerido',
  firstName: 'Primer Nombre',
  secondName: 'Segundo nombre',
  lastName: 'Apellido',
  birthdate: 'Fecha de nacimiento',
  country: 'País',
  selectCountry: 'Selecciona un país',
  completeForm: '¡Tu registro esta completo!',
  thanksRegister: 'Gracias por registrarte, te hemos enviado un correo electrónico para la verificación de tu cuenta. Empieza a jugar con tu crédito de bonificación. Completa tu registro para que sigas jugando y ganando.',
  inviteComplete: 'Lo invitamos a completar su información personal para empezar a jugar.',
  completeButton: 'Completar información',
  titleToastSucces: 'Datos completados con exito',
  descriptionToastSucces: '¡Tu registro ha sido completado con éxito!',
  titleToastError: 'Error al completar el registro',
  descriptionToastError: '¡Ha ocurrido un error al completar el registro, por favor, intenta más tarde.',
  completeFormGame: 'Completa tu registro para empezar a jugar',
  thanksRegisterGame: 'Por favor complete su información personal y realice su primer depósito para empezar a disfrutar de los juegos de casino.',
  completeButtonGame: 'Completar información',
};
