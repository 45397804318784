/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import Image from 'next/image';

import { Session } from 'next-auth';

import { userUIStore } from '@/stores/userUIStore';

import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/components/ui/tabs';

import Nico from '@/public/img/footer/Nico-Master.png';
import payphone from '@/public/img/footer/PayPhone-Master.png';

// import Directa24 from './Directa24';
import NicoPay from './Nico';
import PayPhone from './PayPhone';

const CreditAndDebitCard = ({ session }: { session: Session }) => {
  // const [iframe, setIframe] = useState(false);
  const { setOpenDeposit } = userUIStore();

  return (
    <div className="p-3">
      <Tabs defaultValue="default">
        <TabsList className="grid grid-cols-2 gap-3 w-full h-full bg-sivarbet-secondary-bg py-2 mt-2">
          <TabsTrigger
            value="nico"
            className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
          >
            <Image
              src={Nico}
              alt="Nico"
              width={120}
              height={120}
            />
          </TabsTrigger>

          <TabsTrigger
            value="payphone"
            className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
          >
            <Image src={payphone} alt="PayPhone" width={120} height={120} />
          </TabsTrigger>
          {/* <TabsTrigger value="directa" className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full">
            <Image src={directa} alt="directa" width={70} height={70} className="rounded-full" />
          </TabsTrigger> */}
        </TabsList>
        <TabsContent value="nico">
          <NicoPay session={session} />
        </TabsContent>
        <TabsContent value="payphone">
          <PayPhone session={session} />
        </TabsContent>
        <TabsContent value="default">
          <div className="flex flex-col items-center justify-center h-full">
            <span className="text-sivarbet-text text-center mt-10 text-xl">
              Selecciona la pasarela de pago de tu preferencia
            </span>
          </div>
        </TabsContent>
        {/* <TabsContent value="directa">
          <Directa24 session={session} />
        </TabsContent> */}
      </Tabs>
    </div>
  );
};

export default CreditAndDebitCard;
