import { useRouter } from 'next/navigation';

import './Bonus.css';

export default function SmallPopup({ isVisible, setIsVisible, amount }: { isVisible: boolean, setIsVisible: (isVisible: boolean) => void, amount: number }) {
  const router = useRouter();
  const handleBonusClick = () => {
    router.push('/deportes');
    setIsVisible(false);
  };
  if (!isVisible) return null;

  return (

    <div
      className="absolute inset-0 w-[100vw] h-[100vh] bg-black/50 z-10"
      onClick={() => { return setIsVisible(false); }}
    >
      <div className="fixed top-[15vh] left-1/2 transform -translate-x-1/2  w-full inset-0bg-transparent flex items-center justify-center p-4">
        <div className="relative  bg-gradient-to-b from-sivarbet-border via-sivarbet-secondary-bg to-sivarbet-border p-6 rounded-lg shadow-lg max-w-sm w-full">

          <h2 className="text-lg font-bold text-center mb-5 text-sivarbet-text tracking-wide">
            Tienes bonos deportivos disponibles
          </h2>
          <div className="relative cursor-pointer" onClick={handleBonusClick}>
            <div className=" absolute clipR rounded-e-2 bg-gradient-to-r inset-y-1 from-sivarbet-border form-10% via-sivarbet-primary-hover via-30% to-sivarbet-primary w-28" />
            <div className=" absolute clipL rounded-s-2 end-0 bg-gradient-to-r inset-y-1 from-sivarbet-primary from-70% via-sivarbet-primary-hover via-30% to-sivarbet-border t0-10% w-28" />
            <div className="relative w-10/12 h-[40px] mx-auto bg-gradient-to-r from-sivarbet-primary via-sivarbet-primary-hover to-sivarbet-primary text-white text-center py-3 px-6 rounded-3xl font-bold text-3xl blur-1">
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl">
                {`L ${amount}`}
              </p>
              <div className="absolute w-20 end-0 h-[80%] inset-y-1 bg-gradient-to-r from-sivarbet-primary  via-sivarbet-primary-hover  to-sivarbet-border rounded-full blur-sm" />
              <div className="absolute w-20 start-0 h-[80%] inset-y-1 bg-gradient-to-r from-sivarbet-border via-sivarbet-primary-hover to-sivarbet-primaryrounded-full blur-sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
