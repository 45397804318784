import { createClient, groq } from 'next-sanity';

import { apiVersion, dataset, projectId } from './env';

const client = createClient({
  projectId,
  dataset,
  useCdn: true,
  apiVersion,
});

export async function getSanityPosts(app:string) {
  const data = await client.fetch(
    groq`*[_type == "termsandconditions" && app == $app]{
            _id,
            title,
            "slug" : slug.current,
            body,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            app
        }`,
    { app },
  );
  return data;
}

export async function getSanityPrivacyPolicy(app:string) {
  const data = await client.fetch(
    groq`*[_type == "privacyPolicy" && app == $app]{
            _id,
            title,
            "slug" : slug.current,
            body,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            app
        }`,
    { app },
  );
  return data;
}

export async function getResponsibility(app:string) {
  const data = await client.fetch(
    groq`*[_type == "responsiblegaming" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}
export async function getKycpolicies(app:string) {
  const data = await client.fetch(
    groq`*[_type == "kycpolicies" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getMoneyLaundering(app:string) {
  const data = await client.fetch(
    groq`*[_type == "moneylaundering" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getDisputeResolutionPost(app:string) {
  const data = await client.fetch(
    groq`*[_type == "disputeresolution" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getBettinglimit(app:string) {
  const data = await client.fetch(
    groq`*[_type == "bettinglimit" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getFrequentQuestions(app:string) {
  const data = await client.fetch(
    groq`*[_type == "frequentquestions" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getBettingrules(app:string) {
  const data = await client.fetch(
    groq`*[_type == "bettingrules" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getDepositsWithdrawals(app:string) {
  const data = await client.fetch(
    groq`*[_type == "depositsWithdrawals" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getCategory(app:string) {
  const data = await client.fetch(
    groq`*[_type == "bonustermsandcond" && app == $app]{
            _id,
            title,
            orderRank,
            body,
            app
        }`,
    { app },
  );
  return data;
}

export async function getBannerHomePage() {
  const data = await client.fetch(
    groq`*[_type == "bannerHomePage"]{
             _id,
             title,
             startDate,
             orderRank,
             endDate,
             urlRedirection,
             "alt": image.alt,
             "imageUrl": image.asset->url,
             app,
             isMobile
         }`,
  );
  return data;
}

export async function getBannerSportBook() {
  const data = await client.fetch(
    groq`*[_type == "bannerSportBook"]{
             _id,
             title,
             "alt": image.alt,
             "imageUrl": image.asset->url,
             app,
             isMobile
         }`,
  );
  return data;
}

export async function getBannerCasino() {
  const data = await client.fetch(
    groq`*[_type == "bannerCasino"]{
             _id,
             title,
             startDate,
             orderRank,
             endDate,
             urlRedirection,
             "alt": image.alt,
             "imageUrl": image.asset->url, 
             app,
             isMobile
         }`,
  );
  return data;
}

export async function getBannerSport() {
  const data = await client.fetch(
    groq`*[_type == "bannerSport"]{
             _id,
             title,
             startDate,
             orderRank,
             endDate,
             urlRedirection,
             "alt": image.alt,
             "imageUrl": image.asset->url,
             app
         }`,
  );
  return data;
}

export async function getPromotions() {
  const data = await client.fetch(
    groq`*[_type == "promotions"]{
             _id,
             title,
             startDate,
             orderRank,
             endDate,
             urlRedirection,
             body,
             "alt": image.alt,
             "imageUrl": image.asset->url,
             app
         }`,
  );
  return data;
}
