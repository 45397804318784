'use client';

import { useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter, usePathname } from 'next/navigation';

import { signOut, useSession } from 'next-auth/react';

import LoadingSpinner from '@/components/common/LoadingSpinner';

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';

import avatar from '@/public/img/profile/avatar.png';

const UserMenu = () => {
  const pathname = usePathname();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setActive] = useState(false);
  const { data: session } = useSession();

  const toggleActive = () => {
    setActive(!isActive);
  };

  const handleHeaderBarClick = () => {
    toggleActive();
  };

  const handleLogout = async () => {
    try {
      signOut();

      if (pathname.startsWith('/dashboard')) {
        router.push('/');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Logout failed', error);
    }
  };

  const showSpinner = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <div className="relative">
      {isLoading && <LoadingSpinner />}
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="mr-2 pr-2 w-full">
          <div className="cursor-pointer">
            <Image src={avatar} onClick={handleHeaderBarClick} alt="logo" width={40} height={40} className="rounded-full w-10 md:w-8 lg:w-10 min-w-[32px] min-h-[32px]" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="justify-center flex-col w-56 mt-3 bg-sivarbet-background border-sivarbet-border rounded text-sivarbet-text mr-7">
          <div className="p-2 mt-1 rounded-full bg-sivarbet-secondary-bg text-center">
            <span className="bg-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)] bg-clip-text text-transparent">{session?.user?.nickName}</span>
          </div>
          <Link href="/dashboard" prefetch className="dropdown-item">
            <DropdownMenuItem onClick={showSpinner} className="dropdown-item focus:text-sivarbet-text">
              <span className="icon icon-user text-xl mr-2" />
              <span>Perfil</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator className="bg-sivarbet-border" />
          <Link href="/dashboard/transactions" prefetch className="dropdown-item">
            <DropdownMenuItem onClick={showSpinner} className="dropdown-item focus:text-sivarbet-text">
              <span className="icon icon-transaction text-xl mr-2" />
              <span>Transacciones</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator className="bg-sivarbet-border" />
          <Link href="/dashboard/withdrawals" prefetch className="dropdown-item">
            <DropdownMenuItem onClick={showSpinner} className="dropdown-item focus:text-sivarbet-text">
              <span className="icon icon-withdraw text-xl mr-2" />
              <span>Retiros</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator className="bg-sivarbet-border" />
          <DropdownMenuItem onClick={handleLogout} className="dropdown-item">
            <span className="icon icon-out text-xl mr-2 text-sivarbet-primary" />
            <span className="fw-bold text-sivarbet-primary">Cerrar Sesión</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default UserMenu;
