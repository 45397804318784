import React from 'react';
import '@/features/freespins/Styles/styles.css';

const count = 200;
let points = [];

interface ConfettiProps {
  open: boolean;
}

function Confetti({ open }:ConfettiProps) {
  function generatePoints() {
    points = [];

    for (let i = 0; i < count; i++) {
      const skewY = Math.random() * 50;
      const rotate = Math.random() * 360;
      const translateX = Math.random() * 100;
      const translateY = Math.random() * -200;
      const scale = Math.random() * 0.5 + 0.5;
      const hue = Math.random() * 360;
      const delay = Math.random();
      const bottom = Math.random() * 10;

      const styles = {
        transform: `skewY(${skewY}deg) rotate(${rotate}deg) translate(${translateX}px, ${translateY}px) scale(${scale})`,
        background: `hsla(${hue}, 100%, 50%, 1)`,
        animation: 'bang 2s',
        animationDelay: `${delay}s`,
        bottom: `${bottom}px`,
        opacity: 0.3,
        position: 'absolute' as 'absolute',
        left: '50%',
        width: '5px',
        height: '8px',
        display: 'block',
      };

      points.push(<p className={`${open ? 'animated' : ''}`} key={i} style={styles} />);
    }
    return points;
  }
  const confetti = generatePoints();
  return (
    <div className={`confetti ${open ? 'animated' : ''}`}>
      {open && confetti.map((c) => { return c; })}
    </div>
  );
}

export default Confetti;
