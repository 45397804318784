import React from 'react';

const Copyright = () => {
  return (
    <div className="flex flex-col gap-7 items-center justify-center my-10">
      <div id="anj-d8eac85a-6ddc-4b18-bc18-8d7627783300" data-anj-seal-id="d8eac85a-6ddc-4b18-bc18-8d7627783300" data-anj-image-size="80" data-anj-image-type="basic-small" />
      <span className="text-sivarbet-primary">Copyright © 2024 interplay.tech - Todos los derechos reservados</span>
      <p className="text-wrap text-center text-sivarbet-third-text w-full lg:w-1/2">
        hondubet.com is owned and operated by interplay tech ltd.
        registraon number:000038107, registered address: 9 Barrack Road,
        Belize City, Belize . Contact us info@interplay.tech. hondubet.com is licensed and regulated by the Government of the Autonomous
        Island of Anjouan, Union of Comoros and operates under License No.ALSI-032401020-FI3. hondubet.com has passed all
        regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.
      </p>
    </div>
  );
};

export default Copyright;
