import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import {
  DialogContent,
} from '@/components/ui/dialog';

import { ScrollArea } from '../ui/scroll-area';

// Definición de interfaces
interface Item {
  _id?: string;
  id?: string;
  orderRank?: string;
  body?: any[];
  title?: string;
  // Agrega otros campos segn sea necesario
}

interface ReusableModalProps {
  getData: (app: string) => Promise<Item[]>;
  title: string;
  renderPortableText: (item: string[]) => React.ReactNode;
  app: string;
}

const ReusableModal: React.FC<ReusableModalProps> = ({
  getData,
  title,
  renderPortableText,
  app,
}) => {
  const [data, setData] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchData() {
      const response = await getData(app);
      const sortedData = response.sort((a, b) => { return a.orderRank?.localeCompare(b.orderRank ?? '') ?? 0; });
      setData(sortedData);
    }
    fetchData();
  }, [getData, app]);

  return (
    <DialogContent className="w-[80%] bg-sivarbet-border text-white max-w-full border-none shadow-2xl p-8">
      <div className="grid w-auto gap-4 p-7 text-white bg-sivarbet-background rounded-md">
        <h1 className="text-4xl font-bold text-center w-full">{title}</h1>
        {data.map((item) => {
          return (
            <Accordion key={item.id} type="single" collapsible className="w-full border border-sivarbet-border rounded-md">
              <AccordionItem value={item.title ?? ''} className="border-none">
                <AccordionTrigger className="text-start px-4">{item.title}</AccordionTrigger>
                <AccordionContent className="h-[200px]">
                  <ScrollArea className="h-full w-full rounded-md px-4 border-none">
                    {item.body?.map((i) => {
                      return <div key={i}>{renderPortableText(i.children)}</div>;
                    })}
                  </ScrollArea>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}
      </div>
    </DialogContent>
  );
};

export default ReusableModal;
