/* eslint-disable max-lines-per-function */

'use client';

import React, { useEffect } from 'react';

import Link from 'next/link';
import Script from 'next/script';

import Copyright from '@/features/footer/Copyright';
import FooterLinks from '@/features/footer/FooterLinks';
import Sponsors from '@/features/footer/Sponsors';
import { userUIStore } from '@/stores/userUIStore';

import Android from '@/public/img/footer/android.svg';

import Apple from '@/public/img/footer/apple.svg';

import { Separator } from '../ui/separator';

declare global {
  interface Window { anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045?: { init: () => void; }; }
}

const Footer = () => {
  useEffect(() => {
    if ((window as any).anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045) {
      (window as any).anj_1d4f86fd_7190_4f0e_9c0c_489e7aa1f045.init();
    }
  }, []);

  const { isSidebarOpen } = userUIStore();

  return (
    <>
      <Script
        src="https://d8eac85a-6ddc-4b18-bc18-8d7627783300.snippet.anjouangaming.org/anj-seal.js"
        strategy="afterInteractive"
      />
      <footer className={`bg-gray flex flex-col h-full py-8 px-4 sm:px-8 border-t-2 border-sivarbet-secondary-bg mt-10 ${!isSidebarOpen ? 'lg:pl-80' : 'lg:pl-28'}`}>
        <FooterLinks />
        <Separator className="bg-sivarbet-border my-4" />
        <Sponsors />
        <Separator className="bg-sivarbet-border my-4" />
        <Copyright />
        <div className="flex flex-wrap justify-center items-center gap-4">
          <Link href="/landing/app" className="p-1" style={{ width: '150px', marginTop: '10px' }}>
            <Android />
          </Link>
          <Link href="/landing/app" className="p-1" style={{ width: '150px', marginTop: '10px' }}>
            <Apple />
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
