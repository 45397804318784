import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface AuthState {
  isxtremepushSet: boolean;
  setXtremepush: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => {
      return {
        isxtremepushSet: false,
        setXtremepush: () => { return set({ isxtremepushSet: true }); },
      };
    },
    {
      name: 'auth',
      storage: createJSONStorage(() => { return localStorage; }),
    },
  ),
);
