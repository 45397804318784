import { z } from 'zod';

import { messages } from '@/features/modals/localization';

export const formSchema = z.object({
  name: z.string().min(3, { message: messages.errorInvalidNameMin }).max(50, { message: messages.errorInvalidNameMax }),
  secondname: z.string().optional(),
  lastname: z.string().min(3, { message: messages.errorInvalidLastNameMin }).max(50, { message: messages.errorInvalidLastNameMax }),
  birthdate: z.date({ message: messages.errorInvalidBirthdate }).refine((date) => {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return date > today || date < minDate;
  }, { message: messages.errorInvalidBirthdateMin }),
  country: z.enum(['sv', 'hn'], { message: messages.errorInvalidCountry }),
});
