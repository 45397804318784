import { useState } from 'react';

import { Session } from 'next-auth';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import BankDeposit from './BankDeposit';
import CashDeposit from './CashDeposit';
import CreditAndDebitCard from './CreditAndDebitCard';
import CryptoQRCode from './CryptoQRCode';

const ContentDeposit = ({ session }: { session: Session }) => {
  const [showCryptoForm, setShowCryptoForm] = useState(false);
  const [showDebitCardForm, setShowDebitCardForm] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [showCashForm, setShowCashForm] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  const handleDepositClick = async () => {
    setShowDebitCardForm(false);
    setShowBankForm(false);
    setShowCashForm(false);
    setShowCryptoForm(true);
  };

  const handleDebit = async () => {
    setShowCryptoForm(false);
    setShowBankForm(false);
    setShowCashForm(false);
    setShowDebitCardForm(true);
  };

  const handleBankDeposit = async () => {
    setShowCryptoForm(false);
    setShowDebitCardForm(false);
    setShowCashForm(false);
    setShowBankForm(true);
  };

  // const handleCashDeposit = async () => {
  //   setShowCryptoForm(false);
  //   setShowDebitCardForm(false);
  //   setShowBankForm(false);
  //   setShowCashForm(true);
  // };
  return (
    <div className="space-y-4 h-full">
      <div>
        <p className="text-center mb-7 font-normal">
          Escoge tu método de depósito favorito:
        </p>
        <div className="grid grid-cols-2 gap-4">
          <Button
            className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleDebit}
          >
            <span className="icon icon-credit" />
            <span className="text-lg">Tarjeta de crédito</span>
          </Button>
          <Button
            className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleDepositClick}
          >
            <span className="text-lg icon icon-crypto" />
            <span className="text-lg">Crypto Monedas</span>
          </Button>
          <Button
            className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleBankDeposit}
          >
            <span className="text-lg icon icon-bank" />
            <span className="text-lg">Depósito Bancario</span>
          </Button>
          {/* <Button
            className="flex w-full gap-1 bg-transparent px-0 h-full py-3 font-medium border-2 border-sivarbet-border hover:bg-sivarbet-primary hover:text-sivarbet-border"
            onClick={handleCashDeposit}
          >
            <span className="icon icon-cash" />
            <span className="text-lg">Pago en Efectivo</span>
          </Button> */}
        </div>
      </div>
      {showCryptoForm && (
        <div className="flex-grow overflow-auto">
          <Separator className="bg-sivarbet-border" />
          <CryptoQRCode
            walletAddress={walletAddress}
            setWalletAddress={setWalletAddress}
          />
        </div>
      )}
      {showDebitCardForm && (
        <div className="h-full">
          {' '}
          <Separator className="bg-sivarbet-border" />
          {' '}
          <CreditAndDebitCard session={session} />
          {' '}
        </div>
      )}
      {showBankForm && (
        <div>
          <Separator className="bg-sivarbet-border" />
          <BankDeposit />
        </div>
      )}
      {showCashForm && (
        <div>
          <Separator className="bg-sivarbet-border" />
          <CashDeposit />
        </div>
      )}
    </div>
  );
};

export default ContentDeposit;
