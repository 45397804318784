import React from 'react';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

import { v4 as uuidv4 } from 'uuid';

import {
  Dialog,
  DialogContent,
  DialogHeader,
} from '@/components/ui/dialog';

interface Item {
  games: string[];
}

interface FreeSpindModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: Item[];
}

const FreeSpindModal = React.memo(({ isOpen, onClose, data }:FreeSpindModalProps) => {
  const router = useRouter();
  const games = data.flatMap((item) => { return item.games; });
  const handleFreespinsClick = async (gameCode:string) => {
    router.push(`/casino/play?provider=${gameCode}&gamecode=${gameCode}`);
  };
  if (!isOpen) {
    return null;
  }
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-lg mx-auto border-sivarbet-border bg-sivarbet-background text-sivarbet-text rounded-lg shadow-2xl shadow-black">
        <DialogHeader>
          <h4 className="text-xl font-bold">Free Spins</h4>
        </DialogHeader>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <div className="flex flex-row flex-wrap gap-2 justify-center">
            {games.map((item) => {
              return (
                <div
                  key={uuidv4()}
                  style={{ cursor: 'pointer' }}
                  onClick={() => { handleFreespinsClick(item); }}
                >
                  <Image
                    className="w-52 rounded-2"
                    src={`https://cdn.softswiss.net/i/s2/${item.split(':')[0]}/${item.split(':')[1]}.png`}
                    alt={item.split(':')[1]}
                    width={220}
                    height={120}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});

export default FreeSpindModal;
